import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  MainPage,
  ServicePage,
  CulturePage,
  CommunityPage,
  Service,
  SolutionPage,
} from "../pages/index";
import { Login, ManagerPage, EditDeletePage } from "../manager/page/index";
import {
  ContactUs,
  RandD,
  Network,
  Develop,
  Plan,
  Manage,
  DetailNews,
  DetailDevelop,
} from "./index";
import NewsRoomPost from "../manager/page/NewsRoomPost";
import Privacy from "./Privacy";
import FluxisPrivacy from "./FluxisPrivacy";

interface ContentProps {
  setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Content: React.FC<ContentProps> = ({ setCurrentSection }) => {
  return (
    <div className="Content">
      <Routes>
        <Route
          path="/"
          element={<MainPage setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/service"
          element={<ServicePage setCurrentSection={setCurrentSection} />}
        />
        <Route path="/detailService" element={<Service />} />
        <Route path="/detailSolution" element={<SolutionPage />} />
        <Route
          path="/culture"
          element={<CulturePage setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/detailNews"
          element={<DetailNews setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/community"
          element={<CommunityPage setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/Contact"
          element={<ContactUs setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/RandD"
          element={<RandD setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/network"
          element={<Network setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/develop"
          element={<Develop setCurrentSection={setCurrentSection} />}
        />
        <Route path="/detailDevelop" element={<DetailDevelop />} />
        <Route
          path="/plan"
          element={<Plan setCurrentSection={setCurrentSection} />}
        />
        <Route
          path="/manage"
          element={<Manage setCurrentSection={setCurrentSection} />}
        />
        <Route path="/FourchainsManagerLoginPage" element={<Login />} />
        <Route path="/FourchainsManagerFirstPage" element={<ManagerPage />} />
        <Route
          path="/FourchainsManagerNewsRoomPage"
          element={<NewsRoomPost />}
        />
        <Route path="/ManagerNewsEditDeletePage" element={<EditDeletePage />} />
        <Route path="/cloaksnap/privacy/policy" element={<Privacy />} />
        <Route path="/fluxis/privacy/policy" element={<FluxisPrivacy />} />
      </Routes>
    </div>
  );
};

export default Content;
