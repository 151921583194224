import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Header, Content, Footer } from "./components/index";
import { ManagerHeader } from "./manager/component/index";
import "./style/index.scss";
import { LanguageProvider } from "./locales/LangContext/LanguageContext";

const App = () => {
  const [currentSection, setCurrentSection] = useState("");
  const location = useLocation();
  const pathsWithManagerHeader = [
    "/FourchainsManagerFirstPage",
    "/FourchainsManagerNewsRoomPage",
    "/ManagerNewsEditDeletePage",
    "/cloaksnap/privacy/policy",
    "/fluxis/privacy/policy",
  ];
  const pathsWithoutHeaderAndFooter = [
    "/FourchainsManagerDevPage",
    "/FourchainsManagerLoginPage",
    "/cloaksnap/privacy/policy",
    "/fluxis/privacy/policy",
  ];

  const showHeaderAndFooter = !pathsWithoutHeaderAndFooter.includes(
    location.pathname
  );

  return (
    <LanguageProvider>
      <div className="app">
        {showHeaderAndFooter &&
          ((pathsWithManagerHeader.includes(location.pathname) ? (
            <ManagerHeader />
          ) : (
            <Header
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
          )) as React.ReactNode)}
        <Content setCurrentSection={setCurrentSection} />
        {showHeaderAndFooter &&
          location.pathname !== "/FourchainsManagerFirstPage" &&
          location.pathname !== "/FourchainsManagerFirstPage" &&
          location.pathname !== "/FourchainsManagerNewsRoomPage" &&
          location.pathname !== "/ManagerNewsEditDeletePage" && <Footer />}
      </div>
    </LanguageProvider>
  );
};

export default App;
