/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../style/components/Privacy.scss";

const FluxisPrivacy = () => {
  return (
    <>
      <div id="Back_color">
        <header>
          <h1>FOUR-CHAINS 개인정보 처리 방침</h1>
        </header>
        <div className="images">
          <img
            src={require("../asset/Privacy/FluxisPrivacy1.png")}
            alt="FluxisPrivacy1"
          />
          <img
            src={require("../asset/Privacy/FluxisPrivacy2.png")}
            alt="FluxisPrivacy2"
          />
          <img
            src={require("../asset/Privacy/FluxisPrivacy3.png")}
            alt="FluxisPrivacy3"
          />
          <img
            src={require("../asset/Privacy/FluxisPrivacy4.png")}
            alt="FluxisPrivacy4"
          />
        </div>
      </div>
    </>
  );
};

export default FluxisPrivacy;
